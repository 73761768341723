<template>
  <div class="subjectsManage">
    <el-button type="success"
               icon="el-icon-plus"
               @click="subjectClick">新增学科</el-button>
    <div class="subjectEdit-box">
      <div v-for="subject in subjectList"
           :key="subject.id">
        <!-- <span>序号：</span>
        <el-input v-model="subject.sort_index"
                  :disabled="!subject.isEdit"
                  class="subjectSort-box"></el-input> -->
        <span>名称：</span>
        <el-input v-model="subject.course"
                  :disabled="!subject.isEdit"
                  class="subjectName-box"></el-input>
        <el-button type="primary"
                   v-show="!subject.isEdit"
                   icon="el-icon-edit"
                   @click="editSubject(subject)"
                   size="small"></el-button>
        <el-button type="danger"
                   v-show="!subject.isEdit"
                   icon="el-icon-delete"
                   size="small"
                   @click="subjectDelete(subject.id)"></el-button>
        <el-button type="success"
                   v-show="subject.isEdit"
                   size="small"
                   @click="modifySubjectConfirm(subject)">确定</el-button>
        <el-button v-show="subject.isEdit"
                   size="small"
                   @click="modifySubjectCancel(subject)">取消</el-button>
      </div>
    </div>
    <el-dialog title="新增学科"
               :visible.sync="subjectEditorVisible"
               width="600px">
      <el-form :model="addSubjectForm"
               ref="addSubjectForm"
               :inline="true"
               :rules="addSubjectRules">
        <el-row>
          <!-- <el-col :span="8"> -->
          <!-- <el-form-item label="序号"
                          prop="sort">
              <el-input style="width: 80px"
                        v-model="addSubjectForm.sort"></el-input>
            </el-form-item> -->
          <!-- </el-col> -->
          <!-- <el-col :span="16"> -->
          <el-form-item label="学科名称"
                        prop="subject">
            <el-input v-model="addSubjectForm.subject"></el-input>
          </el-form-item>
          <!-- </el-col> -->
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary"
                   @click="addSubjectConfirm ">确 定</el-button>
        <el-button @click="subjectEditorVisible= false">关 闭 </el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "subjectsManage",
  data() {
    return {
      subjectEditorVisible: false,
      currentSort: "",
      currentName: "",
      subjectList: [],
      courseList: [],
      addSubjectForm: {
        subject: "",
        sort: "",
      },
      addSubjectRules: {
        sort: [
          {
            required: true,
            message: "请输入序号",
            trigger: "blur",
          },
        ],
        subject: [
          {
            required: true,
            message: "请输入学科",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.loadCourse();
  },
  methods: {
    addSubjectConfirm() {
      let data = {
        course: this.addSubjectForm.subject,
        sort_index: 1,
      };
      //   for (let i = 0; i < this.subjectList.length; i++) {
      //     if (
      //       this.subjectList[i].sort_index === Number(this.addSubjectForm.sort)
      //     ) {
      //       this.$message({
      //         type: "warning",
      //         message: "序号重复",
      //       });
      //       return;
      //     }
      //   }
      this.$confirm("是否添加该学科", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/insertCourse.do", data)
            .then((res) => {
              this.$message({
                type: "success",
                message: "添加成功!",
              });
              this.loadCourse();
              this.subjectEditorVisible = false;
            })
            .catch((error) => {
              this.$message.error(error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    loadCourse() {
      this.$post("/media/queryCourse.do", {})
        .then((res) => {
          this.subjectList = [];
          res.data.forEach((subject) => {
            subject.isEdit = false;
            this.subjectList.push(subject);
            this.courseList.push(subject.course);
          });
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    subjectClick() {
      this.subjectEditorVisible = true;
      this.$nextTick(() => {
        this.$refs["addSubjectForm"].resetFields();
      });
    },
    modifySubjectConfirm(subject) {
      this.$confirm("是否修改该学科", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/updateCourse.do", {
            id: subject.id,
            course: subject.course,
            sort_index: 1,
          })
            .then((res) => {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              subject.isEdit = false;
            })
            .catch((error) => {
              this.$message.error(error.message);
              this.subjectList.isEdit = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    modifySubjectCancel(subject) {
      subject.course = this.currentName;
      //   subject.sort_index = this.currentSort;
      subject.isEdit = false;
    },
    subjectDelete(id) {
      this.$confirm("是否删除该学科", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteCourse.do", { id })
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadCourse();
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    editSubject(subject) {
      subject.isEdit = true;
      //   this.currentSort = subject.sort_index;
      this.currentName = subject.course;
    },
  },
};
</script>

<style scoped lang="scss">
.subjectEdit-box {
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  // justify-content: space-between;
  margin-left: 25px;
  // width: 1500px;
}
.subjectEdit-box > div {
  display: flex;
  flex-wrap: wrap;
  font-size: 17px;
  width: 500px;
  margin: 15px 0;
  align-items: center;
}
.subjectSort-box {
  width: 50px;
  margin-right: 15px;
}
.subjectName-box {
  width: 120px;
  margin-right: 15px;
}
.table-box {
  margin-top: 10px;
  // border: 1px solid black;
  border-collapse: collapse;
  font-size: 18px;
  width: 20px;
}
</style>
