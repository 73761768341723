import { render, staticRenderFns } from "./subjectsManage.vue?vue&type=template&id=415749c7&scoped=true&"
import script from "./subjectsManage.vue?vue&type=script&lang=js&"
export * from "./subjectsManage.vue?vue&type=script&lang=js&"
import style0 from "./subjectsManage.vue?vue&type=style&index=0&id=415749c7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "415749c7",
  null
  
)

export default component.exports